import React from 'react';
import { connect } from 'dva';
import { Button, Spin, Row, Col } from 'antd';
import intl from 'react-intl-universal';
import { routerRedux } from 'dva/router';

import Subscribe from '../../assets/Group.png';
import imag from '../../assets/Shopify.png';
import guideImg from '../../assets/img/guide-shopify-account.png';
import guideImg2 from '../../assets/img/guide-shopify-account2.png';
import getPaymentData from '../../utils/getPaymentData';
import styles from './Index.less';
import { Get } from 'utils/request';

@connect(({ login }) => ({
  userInfo: login.userInfo
}))
export default class Pricing extends React.PureComponent {
  checkTimer = null;

  constructor(props) {
    super(props);
    this.state = {
      newName: '',
      oldName: '',
      payment: {},
      charge_id:0
    };
  }

  componentDidMount() {
    // 检测 payment 数据变化
    this.checkTimer = setInterval(this.check, 1000);

    this.getChargeId()
  }

  componentWillUnmount() {
    if (this.checkTimer) {
      clearInterval(this.checkTimer);
    }
  }

  getChargeId = async () => {
    const res = await Get('/charge/charge_id',{})
    this.setState({
      charge_id:res.data.charge_id
    })
  }

  check = () => {
    const { userInfo } = this.props;
    const payment = getPaymentData();

    if (!(payment && userInfo)) {
      return;
    }

    clearInterval(this.checkTimer);

    if (userInfo.stores.length > 0 && payment.type > 0 && payment.status == 2) {
      window.location.href = '/app/'; // 首页
    } else if (
      userInfo.stores.length > 0 &&
      payment.type > 0 &&
      payment.status == 4
    ) {
      window.location.href = '/app/'; // 首页
    }

    const tmp = userInfo.stores.find(i => i.id == payment.default_store_id);
    let newName;

    if (tmp) {
      newName = tmp.domain;
    }

    this.setState({
      newName,
      oldName: `${payment.old_billing_store_name}.myshopify.com`,
      payment
    });
  };

  // static getDerivedStateFromProps(props, state) {
  //   // 组件每次被rerender的时候，包括在组件构建之后(虚拟dom之后，实际dom挂载之前)，每次获取新的props或state之后；;每次接收新的props之后都会返回一个对象作为新的state，返回null则说明不需要更新state
  //   const payment = JSON.parse(localStorage.getItem('payment') || {});
  //   const { stores } = JSON.parse(localStorage.getItem('store')) || {};

  //   if (stores.length > 0 && payment.type > 0 && payment.status == 2) {
  //     window.location.href = '/storemanage/myproduct';
  //   } else if (stores.length > 0 && payment.type > 0 && payment.status == 4) {
  //     window.location.href = '/storemanage/myproduct';
  //   }

  //   stores &&
  //     stores.map(item => {
  //       if (item.id == payment.default_store_id) {
  //         state.newName = item.domain;
  //       }
  //     });

  //   state.payment = payment;
  //   state.oldName = payment.old_billing_store_name;
  //   return state;
  // }

  Subscribe = () => {
    this.props.dispatch({
      type: 'guidance/reorder',
      payload: {
        callback: d => {
          if (d.code == 2000) {
            const url = d.data.confirm_url;
            if (url) {
              window.open(url);
            }
          }
        }
      }
    });
  };

  jump = () => {
    window.location.href = '/app/select';
  };

  jump2 = () => {
    window.location.href = '/app/pricing';
  };

  getTargetUrl = url => {
    // eslint-disable-next-line
    let target = 'javascript:;';
    if (!url) {
      return target;
    }

    let urlObject = null;
    try {
      urlObject = new URL(url.trim());
    } catch (error) {
      console.log('getTargetUrl error:', error);
    }

    if (urlObject && urlObject.origin) {
      target = `${urlObject.origin}/admin/apps`;
    }

    return target;
  };

  renderBtn = payment => {
    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        <Button
          onClick={this.Subscribe}
          style={{ marginRight: 15 }}
          type="primary"
        >
          {intl.get('pricing.Subscribe')}
        </Button>
        <Button onClick={this.jump} style={{ marginRight: 15 }} type="primary">
          {/* {intl.get('pricing.ChangePlan')} */}
          Change Billing Store
        </Button>

        <Button onClick={this.jump2} style={{ marginRight: 15 }} type="primary">
          {intl.get('pricing.ChangePlan')}
        </Button>

        <a href="mailto:support@dsers.com" target="_blank">
          {intl.get('pricing.Contnct')} us
        </a>
      </div>
    );
  };

  render() {
    const { newName, oldName, payment, charge_id } = this.state;

    if (!(payment && Object.keys(payment).length !== 0)) {
      return (
        <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
      );
    }

    const shopifyUrl = this.getTargetUrl(payment.confirm_url);

    // 注意 !!! 以下每种情况对应的逻辑写一起，不要为了复用糅杂在一起

    return (
      <Row type="flex" justify="center">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 18 }}
          lg={{ span: 14 }}
          xl={{ span: 10 }}
        >
          <div className={styles.SubscribeCenter}>
            {payment.old_billing_store_name && payment.status === 3 && payment.period === 1 ? (
              <div className={styles.free}>
                <div className={styles.freeLeft}>
                  <div style={{ marginBottom: 24 }}>
                    <p className={styles.like}>
                      {intl.getHTML('pricing.modification', {
                        new: newName,
                        old: oldName
                      })}
                    </p>
                    <p style={{ marginTop: 24 }}>
                      {intl.getHTML('pricing.subscription_network_tip',{jumpUrl:`/app/reminder?charge_id=${charge_id}`})}
                    </p>
                  </div>
                  {this.renderBtn(payment)}
                </div>
                <img src={Subscribe} />
              </div>
            ) : null}

            {payment.status === 3 && payment.period === 2 ? (
              <div className={styles.free}>
                <div className={styles.freeLeft}>
                  <div style={{ marginBottom: 24 }}>
                    <p className={styles.working}>{intl.get('pricing.working')}</p>
                    <p className={styles.like}>{intl.get('pricing.like')}</p>
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <p className={styles.over}>{intl.get('pricing.over')}</p>
                    <p>{intl.get('pricing.confirm')}</p>
                    <p style={{ marginTop: 24 }}>
                      {intl.getHTML('pricing.subscription_network_tip',{jumpUrl:`/app/reminder?charge_id=${charge_id}`})}
                    </p>
                  </div>
                  {this.renderBtn(payment)}
                </div>
                <img src={Subscribe} />
              </div>
            ) : null}

            {payment.status === 1 ? (
              <>
                <p>
                  {intl.getHTML('pricing.Unfinishedfront')}
                  <a
                    role="button"
                    tab-index="0"
                    onClick={this.Subscribe}
                    className={styles.Unfin}
                  >
                    &nbsp;
                    {intl.getHTML('pricing.Unfinishedcentre')}
                    &nbsp;
                  </a>
                  {intl.getHTML('pricing.Unfinishedqueen')}
                </p>
                {this.renderBtn(payment)}
                <p style={{ paddingTop: 20 }}>
                  {intl.getHTML('pricing.Unfinished')}
                </p>
                <img src={imag} className={styles.guideStore} />
                <p style={{ paddingTop: 20 }}>
                  {intl.getHTML('pricing.if_free_dec')}
                </p>
                <div className={styles.guideImg}>
                  <img src={guideImg} />
                  <img src={guideImg2} />
                </div>
                <p style={{ paddingTop: 20 }}>
                  <a
                    href={shopifyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button type="primary">
                      {intl.get('pricing.go_to_shopify')}
                    </Button>
                  </a>
                </p>
                <p style={{ paddingTop: 20 }}>
                  {intl.getHTML('pricing.UnfinishedNote')}
                </p>
              </>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  }
}
